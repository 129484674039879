import React from 'react';
import Layout from '../components/layout';
import styles from './about-me.module.scss';

const Index = () => (
  <Layout>
    <div className={styles.aboutMe}>
      <section className={styles.intro}>
        <div className={styles.presentation}>
          <p>
            My name is Iwona. Since I came to UK I started to run my own
            practice as a Speech and Language Therapist for Polish kids.
          </p>
          <div className={styles.presentationpicturewrapper}>
            <div className={styles.presentationLeft}>
              <p>
                I became a part of big Polish community in Watford and also
                started to learn in Polish Saturday School in Watford as a
                teacher. But my biggest passion is being a Speech Therapist and
                supporting families and kids with developing their language
                skills.
              </p>
            </div>
            <div className={styles.presentationPicture}>
              <img src="/images/iwona.jpg" />
            </div>
          </div>
          <p>
            The most important aim for me is keeping my knowledge on the highest
            level, so I work hard and take part in courses and trainings. Last
            year I started additional post graduated studies that empower me to
            work with small babies, so now I can work not only as a Speech and
            Language Therapist, but also as a support for new parent and their
            newborn babies.
          </p>
        </div>
        <div className={styles.details}>
          <h4>
            What I can do exactly? I can give you professional advices about:
          </h4>
          <ul>
            <li>
              strengthening the emotional bond between parents and the child,
              recognizing the child's behavior and consolidating appropriate
              responses to these behaviors
            </li>
            <li>
              providing instruction and advice as well as consultations for
              parents regarding work with child
            </li>
            <li>
              helping the child make the most of the development potential he
              has in cognitive functions, motor development, communication and
              social contacts
            </li>
            <li>
              preventing the occurrence or worsening of irregularities in the
              child's psychomotor development
              <ul>
                <li>
                  supervising the proper development of the child according to
                  standardized tests
                </li>
                <li>helping with breastfeeding</li>
                <li>helping with daily routine with your baby</li>
                <li>support with developing babies language skills </li>
                <li>and much more...</li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
      <div className={styles.subsection}>
        <h2>
          I am also Shantala massage instructor and I run baby massage group for
          parents.
        </h2>
        <p>If you want to know more about me, please call or mail me.</p>
        <p>I am very happy and excited to help you with your new journey.</p>
      </div>
      <div className={styles.qualificationsWrapper}>
        <h3>My qualification</h3>
        <div className={styles.qualifications}>
          <div className={styles.studies}>
            <h4>Studies</h4>
            <ul>
              <li>
                master degree, studies of Silesia University in Katowice, Poland
                - Polish language teacher (all stages)
              </li>
              <li>
                post graduate studies, Humanites Academy in Bielsko-Biała,
                Poland - Speech and Language Therapist
              </li>
              <li>
                post graduate studies, Korczak Pedagogical School in Warsaw,
                Poland – neuro Speech and Language Therapist and specialist in
                early support of child development-in progress
              </li>
            </ul>
          </div>
          <div className={styles.trainings}>
            <h4>Courses and trainings:</h4>
            <ul>
              <li>Shantala baby massage instructor</li>
              <li>AAC Communication specialist-Macaton</li>
              <li>
                verbo-tonal method in the therapy of a child with impaired
                speech development
              </li>
              <li>
                hand therapy and small motor disorders - diagnosis, therapy,
                massage
              </li>
              <li>neurobiological therapy for beginners</li>
              <li>
                language programming for children with communication disorders
                (autistic, deaf, with aphasia)
              </li>
              <li>kinesitherapy in speech therapy</li>
              <li>and a lot in progress</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Index;
